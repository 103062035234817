import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["timer", "alert"];
  static values = { duration: Number };

  connect() {
    this.remainingSeconds = 4;
    this.startCountdown();
  }

  startCountdown() {
    this.updateTimer();
    this.interval = setInterval(() => {
      this.remainingSeconds -= 1;
      if (this.remainingSeconds <= 0) {
        this.stopCountdown();
        Turbo.visit('/search');
      } else {
        this.updateTimer();
      }
    }, 1000);
  }

  stopCountdown() {
    this.alertTarget.innerHTML = 'Redirecting to search dashboard...'
    clearInterval(this.interval);
  }

  updateTimer() {
    this.timerTarget.textContent = this.remainingSeconds;
  }
}
