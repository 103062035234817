window.removeClass = function(element, classes) {
  classes.split(' ').forEach(className => element?.classList.remove(className));
};

window.addClass = function(element, classes) {
  classes.split(' ').forEach(className => element?.classList.add(className));
};

window.toggleClass = function(element, classes) {
  classes.split(' ').forEach(className => element?.classList.toggle(className));
};

window.hasClass = function(element, className) {
  return element.classList.contains(className);
};

window.show = function(element) {
  removeClass(element, 'hidden');
};

window.hide = function(element) {
  addClass(element, 'hidden');
};

window.addCustomEventListener = function(eventName, that) {
  document.addEventListener(eventName, (e) => { that[eventName](e) });
};

window.removeCustomEventListener = function(eventName, that) {
  document.removeEventListener(eventName, (e) => { that[eventName](e) }, true);
};

window.triggerEvent = function(target, eventName, data) {
  if (typeof data === 'undefined') {
    target.dispatchEvent(new Event(eventName));
  } else {
    const eventData = { bubbles: true, detail: data }
    target.dispatchEvent(new CustomEvent(eventName, eventData));
  }
};

window.tryCatch = function(callback) {
  try {
    callback.call()
  }
  catch(err) { return false }
}

window.enableAllInputs = function(target) {
  target.querySelectorAll('input, select, textarea').forEach(element => element.disabled = false);
};

window.disableAllInputs = function(target) {
  target.querySelectorAll('input, select, textarea').forEach(element => element.disabled = true);
};

window.requiredToOptional = function(target) {
  target
    .querySelectorAll('input[required="required"], textarea[required="required"], select[required="required"]')
    .forEach(element => {
      element.removeAttribute('required');
      element.setAttribute('_required', 'required')
    });
};

window.optionalToRequired = function(target) {
  target
    .querySelectorAll('input[_required="required"], textarea[_required="required"], select[_required="required"]')
    .forEach(element => {
      element.removeAttribute('_required');
      element.setAttribute('required', 'required')
    });
};

window.turboFetch = function(url, callback) {
  window.fetch(url, {
    headers: {
      Accept: "text/vnd.turbo-stream.html",
    },
  }).then(r => r.text()).then(html => {
    Turbo.renderStreamMessage(html)

    return true
  }).then(data => tryCatch(() => callback(data)));
}

window.turboPath = function(form, url, callback) {
  const action = url || form.getAttribute('action');
  const method = form.getAttribute('method') || 'post';
  const params = new FormData(form);
  params.append('submit', `turbo_force_${method}`);
  params.append('format', `turbo_stream`);

  window.fetch(action, {
    method: method,
    headers: {
      Accept: "text/vnd.turbo-stream.html",
    },
    body: params
  }).then(r => r.text()).then(html => {
    Turbo.renderStreamMessage(html)

    return true
  }).then(data => tryCatch(() => callback(data)));
}

window.presence = function(value, defaultValue) {
  const empty = (value === '' || value === null || value === undefined)

  if (empty) { return defaultValue }
  else { return value }
}

window.uuidv4 = function() {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ window.crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

window.src = function(query_selector) {
  return document.getElementById(query_selector).getAttribute('src')
}

window.capitalize = function(s) {
  return s && s[0].toUpperCase() + s.slice(1);
}

window.waitForElement = function(selector) {
  return new Promise(resolve => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector))
    }

    const observer = new MutationObserver(mutations => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector))
        observer.disconnect()
      }
    })

    observer.observe(document.body, {
      childList: true,
      subtree: true
    })
  })
}

window.toNumberFormat = function(value, currency = 'EUR', without_symbol = true) {
  const formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: currency,
    currencyDisplay: "code"
  });

  amount = formatter.format(value)
  if (without_symbol === true) { amount = amount.replace(currency, "").trim() }

  return amount
}

window.removeDatasets = function(element, datasets) {
  datasets.split(' ').forEach(name => delete element.dataset[name]);
}

window.currencyToFloat = function(value) {
  return parseFloat(value.replace('.', "").replace(',', ".")).toFixed(2)
}

window.numberStringToFloat = function(value) {
  return parseFloat(value.replace(',', ".").replace(',', "."))
}

window.toMMSS = function(seconds) {
  var hours = Math.floor(seconds / 3600);
  var minutes = Math.floor((seconds - (hours * 3600)) / 60);
  var seconds = seconds - (hours * 3600) - (minutes * 60);

  if (minutes < 10) {
    minutes = "0" + minutes;
  }

  if (seconds < 10) {
    seconds = "0" + seconds;
  }

  // return `${hours}:${minutes}:${seconds}`
  return `${minutes}:${seconds}`
}
