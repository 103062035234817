import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["text"]

  connect() {
    this.sloganArray = [
      'Get the LinkedIn B2B data to grow your business',
      'Start your sales by reaching the right decision makers',
      'Search database of 500 Million Business Emails',
      'Search 120M Personal Emails and 20M Phone Numbers'
    ]
    this.sloganIndex = 0
    this.lastIntervalTimestamp = 0

    // Set initial slogan
    this.textTarget.innerHTML = this.sloganArray[this.sloganIndex]
    this.fadeIn()

    // Start the animation loop
    requestAnimationFrame(this.render.bind(this))
  }

  render(now) {
    if (!this.lastIntervalTimestamp || now - this.lastIntervalTimestamp >= 5 * 1000) {
      this.lastIntervalTimestamp = now

      // Fade out the current text
      this.fadeOut()

      // Wait for the fadeOut transition to complete before updating the text
      setTimeout(() => {
        this.updateText()
        this.fadeIn()
      }, 1000)
    }

    // Rerun the function
    requestAnimationFrame(this.render.bind(this))
  }

  updateText() {
    // Progress the slogan index along by 1
    this.sloganIndex++

    // If the slogan index is at the last of the slogan array, reset to 0
    if (this.sloganIndex >= this.sloganArray.length) {
      this.sloganIndex = 0
    }

    // Update the slogan in the container
    this.textTarget.innerHTML = this.sloganArray[this.sloganIndex]
  }

  fadeIn() {
    this.textTarget.style.opacity = 1
  }

  fadeOut() {
    this.textTarget.style.opacity = 0
  }
}
