import { Controller } from "@hotwired/stimulus"
import Trix from "trix"
import sanitizeHtml from 'sanitize-html'

export default class extends Controller {
  static targets = ["body", "unit", "template", "form"]

  connect() {
    const that = this;
    this.lastCursorPosition = null;
    this.addTrixToolbar = this.addTrixToolbar.bind(this)
    this.addTrixToolbars = this.addTrixToolbars.bind(this)
    this.selectUnit = this.selectUnit.bind(this)
    this.filterUser = this.filterUser.bind(this)

    document.addEventListener("trix-initialize", function(event) {
      const trixEditor = event.target;

      trixEditor.addEventListener("paste", function(ev) {
        const clipboardData = ev.clipboardData || window.clipboardData;
        if (!clipboardData) return;

        const html = clipboardData.getData("text/html");
        const plainText = clipboardData.getData("text/plain");
        if (html) {
          // Prevent default paste behavior
          ev.preventDefault();
          ev.stopPropagation();
          // Undo the paste
          trixEditor.editor.undo();
          // Insert the HTML as-is
          trixEditor.editor.insertHTML(html);
        } else if (plainText) {
          // If plain text is pasted, wrap it in a <pre> tag for code
          ev.preventDefault();
          ev.stopPropagation();
          // Undo the paste
          trixEditor.editor.undo();
          // Insert the HTML as-is
          trixEditor.editor.insertHTML(sanitizeHtml(plainText));
        }
      });
    });

    document.addEventListener('trix-initialize', this.addTrixToolbars, { once: true });
    this.templateTarget.addEventListener('change', function(){
      turboFetch(that.templateTarget.dataset.templateUrl.replace('__ID__', this.value), function(){
      })
    })
  }

  filterUser() {
    const frame = document.getElementById('pagination')
    const formData = new FormData(this.formTarget);
    const newUrl = `${frame.src.split('?')[0]}?page=1&filter[from_uid]=${formData.get('email[from_uid]')}&filter[to_uid]=${formData.get('email[to_uid]')}&filter[selected_option]=${formData.get('selected_option') || ''}`;
    frame.removeAttribute('complete')
    frame.removeAttribute('loading')
    frame.src = null;
    frame.src = newUrl;
    frame.reload()
  }

  addTrixToolbars(event) {
    const that = this;
    const editor = event.target.editor;
    let toolbar = document.querySelector('.trix-button-group-spacer')
    Array.from(['first_name', 'last_name', 'company', 'phone', 'unsub']).forEach(key => this.addTrixToolbar(toolbar, key))

    // Event listener for cursor change
    editor.element.addEventListener('trix-selection-change', function(event) {
      that.lastCursorPosition = editor.getSelectedRange();
    });

    // Event listener for custom button
    toolbar.addEventListener('click', function(event) {
      if (event.target.getAttribute('data-trix-attribute') === 'keytext') {
        const keyText = event.target.getAttribute('data-trix-value');
        if (that.lastCursorPosition !== null) {
          editor.setSelectedRange(that.lastCursorPosition);
          editor.insertString(keyText);
        } else {
          const currentPosition = editor.getSelectedRange();
          editor.insertString(keyText, currentPosition[0]);
        }
      }
    });
  }

  addTrixToolbar(toolbar, button) {
    toolbar.insertAdjacentHTML(
      'beforeend',
      `<span class="trix-button-group-button inline-flex items-center px-2 py-1 me-1 text-sm font-medium text-gray-800 bg-gray-100 rounded dark:bg-gray-700 dark:text-gray-300" data-trix-attribute="keytext" data-trix-value="[${button}]" title="${button}">[${button}]</span>`
    );
  }

  selectUnit(event) {
    this.unitTarget.innerHTML = event.target.value
  }
}
