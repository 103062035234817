import { Controller } from "@hotwired/stimulus"
import { initFlowbite } from "flowbite"
export default class extends Controller {
  connect() {
    document.addEventListener("turbo:before-stream-render", (event) => {
      const originalRender = event.detail.render

      event.detail.render = function (streamElement) {
        originalRender(streamElement)
        initFlowbite()
      }
    })
  }
}
