import { Controller } from "@hotwired/stimulus"
import { initFlowbite } from "flowbite"
// import { updateToolbars } from "../helpers/trix"

export default class extends Controller {
  connect() {
    this.switchThemeMode = this.switchThemeMode.bind(this);
    this.setThemeMode = this.setThemeMode.bind(this);

    document.addEventListener("turbo:before-stream-render", (event) => {
      const originalRender = event.detail.render

      event.detail.render = function (streamElement) {
        originalRender(streamElement)
        initFlowbite()
        // updateToolbars(event)
      }
    })

    if (document.getElementById('cssDark')) {
      this.setThemeMode()
    }
  }

  switchThemeMode() {
    let mode = localStorage.getItem('color-theme');
    this.setThemeMode(mode == "light" ? "dark" : "light")
  }

  setThemeMode(mode) {
    mode ||= localStorage.getItem('color-theme');
    const $body = document.body;
    const $html = document.documentElement;
    const $darkTarget = document.getElementById('cssDark');
    const $ligtTarget = document.getElementById('cssLight');

    switch (mode) {
      case 'dark':
          $html.classList.remove('light');
          $html.classList.add('dark');
          $html.dataset.theme = 'dark';
          $html.dataset.bsTheme = 'dark';
          $body.dataset.colorScheme = 'dark';
          $body.dataset.bsTheme = 'dark';
          $darkTarget.removeAttribute('disabled')
          localStorage.setItem('color-theme', 'dark');
        break;

      default:
        $html.classList.remove('dark');
        $html.classList.add('light');
        $html.dataset.theme = 'light';
        $html.dataset.bsTheme = 'light';
        $body.dataset.colorScheme = 'light';
        $body.dataset.bsTheme = 'light';
        $darkTarget.setAttribute('disabled', true)
        localStorage.setItem('color-theme', 'light');
        break;
    }
  }
}
