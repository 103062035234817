import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["header", "body", "footer"]

  initialize() {
    const that = this
    // options with default values
    this.options = {
      placement: 'bottom-right',
      backdrop: 'dynamic',
      backdropClasses:
        'bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-[140]',
      closable: true,
      onHide: () => {
        document.querySelectorAll('div[modal-backdrop]').forEach((element) => {
          element.remove();
        });
        // document.querySelector("body > div[modal-backdrop]")?.remove()
      },
      onShow: () => {
      },
      onToggle: () => {
      },
    };

    // instance options object
    this.instanceOptions = {
      id: 'global-modal',
      override: true
    };
  }

  connect() {
    const that = this
    this.modalTarget = document.getElementById('global-modal')
    this.modal = new Modal(this.modalTarget, this.options, this.instanceOptions);
    this.hideModal = this.hideModal.bind(this)
    this.submitEnd = this.submitEnd.bind(this)

    document.querySelectorAll('[data-modal-hide]').forEach((element) => {
      element.addEventListener('click', (e) => {
        that.modal.hide();
      });
    });

    this.modalTarget.addEventListener('click', function(event) {
      if (event.target.getAttribute('data-modal-hide') == 'global-modal') {
        that.modal.hide();
      }
    })
  }

  hideModal() {
    this.modal.show();
  }

  // action: "turbo:submit-end->turbo-modal#submitStart"
  submitStart(e) {
    console.log('submitEnd', e)
  }

  // hide modal on successful form submission
  // action: "turbo:submit-end->turbo-modal#submitEnd"
  submitEnd(e) {
    console.log('submitEnd', e)
    if (e.detail.success) {
      this.modal.hide();
    }
  }
}
